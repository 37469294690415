<template>
  <!-- 品牌商城系统-->
  <div class="wrapper">
    <Header></Header>
    <div class="body">
      <div class="pic1">
        <img class="img" :src="img1">
      </div>
      <div class="link1">
        <div class="title1">
            <div class="title1-big">选择品牌商城能为你带来什么</div>
            <div class="title1-small">随着移动电商时代的降临，机遇与竞争并存，</div>
        </div>
        <div class="forms1">
            <div class="form1" v-for="(form,index) in forms1" :key="index">
            <div class="form1_icon">
                <img class="form1_img" :src="form.img">
            </div>
            <div class="form1_content">
                <div class="form1_title">{{form.title}}</div>
                <div class="form1_text">{{form.text}}</div>
            </div>
            </div>
        </div>
      </div>
      <Reserve></Reserve>
      <div class="link2">
        <div class="title2">
            <div class="title2-big">3端商城建设</div>
            <div class="title2-small">助力企业抓住各个流量入口</div>
        </div>
        <div class="forms2">
            <div class="form2" v-for="(form,index) in forms2" :key="index">
              <div class="form2_icon">
                  <img class="form2_img" :src="form.img">
              </div>
              <div class="form2_content">
                  <div class="form2_title">{{form.title}}</div>
                  <div class="form2_text">{{form.text}}</div>
              </div>
            </div>
        </div>
        <Reserve></Reserve>
      </div>
      <div class="link1">
        <div class="title1">
            <div class="title1-big">选择品牌商城能为你带来什么</div>
            <div class="title1-small">随着移动电商时代的降临，机遇与竞争并存，</div>
        </div>
        <div class="forms3">
          <div class="form3" v-for="(form,index) in forms3" :key="index" :style="{backgroundColor: form.bgcolor, color: form.color}">
            <div class="form3_top">
              <div class="form3_icon">
                <img :src="form.img">
              </div>
              <div class="form3_title">{{form.title}}</div>
            </div>
            <div class="form3_bottom">{{form.text}}</div>
          </div>
        </div>
        <Reserve></Reserve>
      </div>
      <Record></Record>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/header.vue'
import Reserve from '../components/reserve.vue'
import Record from '../components/record.vue'
import Footer from '../components/footer.vue'

export default {
  name: 'page8',
  components: {
    Header,
    Reserve,
    Record,
    Footer
  },
  data () {
    return {
      img1: require('@/assets/img/page8.1.png'),
      forms1: [{
        img: require('@/assets/img/page8.2.1.png'),
        title: '10大营销策略任性使用',
        text: '限时秒杀，满减优惠，发红包，优惠券等营销方案齐全，任性使用'
      }, {
        img: require('@/assets/img/page8.2.2.png'),
        title: '7大类商品管理方式全面便捷',
        text: '商品分类,商品属性,商品规格以及评价等全方位打造完善的商品体系'
      }, {
        img: require('@/assets/img/page8.2.3.png'),
        title: '8类用户后台管理权限',
        text: '后台操作权限分类管理，方便企业管理，保护后台重要数据，安全，专一，专注'
      }, {
        img: require('@/assets/img/page8.2.4.png'),
        title: '5项流程无缝对接',
        text: '商品,订单,账户资金,物流及分销等流程管理无缝对接,系统全面操作管理便捷'
      }],
      forms2: [{
        img: require('@/assets/img/page8.3.1.png'),
        title: '小程序商城',
        text: '主打微信小程序，可开发抖音、支付宝、百度等小程序，这些大平台有大量的流量可以去用，传播力也很强'
      }, {
        img: require('@/assets/img/page8.3.2.png'),
        title: '手机微商城',
        text: '商品分类,商品属性,商品规格以及评价等全方位打造完善的商品体系'
      }, {
        img: require('@/assets/img/page8.3.3.png'),
        title: '电脑商城',
        text: '适配各类主流浏览器，掌握PC全渠道，不放过任何一个流量入口，实现全网营销'
      }],
      forms3: [{
        img: require('@/assets/img/page8.4.1.png'),
        bgcolor: '#E1E4ED',
        color: '#1E1E1E',
        title: '品牌商城不断迭代',
        text: '在数字无处不在的今天，通过数字来理解市场，理解用户，理解企业经营，已经是企业运营绕不开的话题。品牌商城很有必要打造，它在这方面具有很高的价值，而且这个产品一直在迭代更新，未来可以满足更多的用户需求'
      }, {
        img: require('@/assets/img/page8.4.2.png'),
        bgcolor: '#4070F4',
        color: '#FFFFFF',
        title: '品牌商城更适合创业公司起步',
        text: '越来越多的人去创业，但最后能存活下来的企业少之又少，其根本原因是现在创业确实不容易，其次就是创业者本身经验不足，不断的摸索中就会不断的消耗资本，最后资本没了也就创业失败了。但是品牌商城凭借着超低的价格可以帮助创业者去试错，减少不必要的成本支出，大大的提高创业成功率。'
      }, {
        img: require('@/assets/img/page8.4.3.png'),
        bgcolor: '#1B1B38',
        color: '#FFFFFF',
        title: '品牌商城可定制化',
        text: '可以根据客户需要去定制属于自己的品牌商城，可增加或者减少功能，定制费用上，我们也是根据定制功能的多少来衡量开发成本。最后肯定是给到客户一个合理的报价，一般定制额外产生的费用在5000-500000之间不等。'
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  position: relative;
  z-index: 1;
  margin: 0.42rem -0.08rem 0 -0.08rem;
}
.pic1 {
  height: 2.02rem;
  margin-bottom: 0.16rem;
}
img {
 width: 100%;
}
.link1 {
  text-align: center;
  .title1 {
    .title1-big {
      font-size: 0.17rem;
      font-family: PingFangSC-Medium;
      color: #1e1e1e;
      margin-bottom: 0.06rem;
    }
    .title1-small {
      font-family: PingFangSC-Regular;
      color: #666666
    }
  }
  .forms1 {
    margin: 0.15rem 0.16rem  0 0.16rem;
    .form1 {
      position: relative;
      display: flex;
      margin-bottom: 0.08rem;
      border-radius: 0.08rem;
      box-shadow: 0 0.01rem 0.04rem 0 rgba(0, 0, 0, 0.16);
      .form1_icon {
        margin: 0.21rem 0.19rem 0 0.17rem;
        width: 0.3rem;
      }
      .form1_content {
        text-align: start;
        margin: 0.16rem 0.28rem 0.15rem 0;
        .form1_title {
          margin-bottom: 0.02rem;
          font-family: PingFangSC-Medium;
          font-size: 0.14rem;
        }
        .form1_text {
          font-family: PingFangSC-Regular;
          font-size: 0.12rem;
          color: #777777
        }
      }
    }
  }
  .forms3 {
    margin: 0.16rem 0.16rem 0 0.16rem;
    .form3 {
      margin-bottom: 0.12rem;
      .form3_top {
        display: flex;
        align-items: center;
        padding: 0.12rem 0 0.12rem 0.16rem;
        .form3_icon {
          width: 0.24rem;
          margin-right: 0.13rem;
        }
        .form_title {
          font-family: PingFangSC-Medium;
          width: 1.56rem;
        }
      }
      .form3_bottom {
        font-family: PingFangSC-Regula;
        padding: 0 0.16rem 0.2rem 0.16rem;
        text-align: start;
      }
    }
  }
}
.link2 {
  text-align: center;
  background-color: #f7f7f7;
  padding-bottom: 0.01rem;
  margin-bottom: 0.2rem;
  .title2 {
    padding-top: 0.2rem;
    .title2-big {
      font-size: 0.17rem;
      font-family: PingFangSC-Medium;
      color: #1e1e1e;
      margin-bottom: 0.06rem;
    }
    .title2-small {
      font-family: PingFangSC-Regular;
      color: #666666
    }
  }
  .forms2 {
    margin: 0.24rem 0.24rem 0 0.25rem;
    .form2 {
      margin-bottom: 0.16rem;
      display: flex;
      justify-content: center;
      .form2_icon {
        width: 0.2rem;
        margin-right: 0.21rem;
      }
      .form2_content {
        text-align: start;
        width: 2.91rem;
        .form2_title {
          font-family: PingFangSC-Regula;
          color: #333333;
          margin-bottom: 0.04rem;
        }
      }
    }
  }
}
</style>
